import request from '@/utils/request.js'
import { urlPath } from './uiPage'

// 查询步骤列表信息
export const queryEnvList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiEnv',
    params: {
      ...data
    }
  })
}

// 依据ID查询步骤信息
export const queryEnvById = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiEnv',
    params: {
      ...data
    }
  })
}

// 添加步骤信息
export const addEnv = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'uiEnv',
    data
  })
}

// 编辑步骤信息
export const editEnv = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'uiEnv',
    data
  })
}

// 删除步骤信息
export const deleteEnv = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'uiEnv',
    data
  })
}
