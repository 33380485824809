<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        用例管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <div>
          <el-button type="primary" @click="handleAdd">新增用例</el-button>
          <el-button type="primary" @click="handleRunDialog">执行用例</el-button>
        </div>
        <div style="margin-top: 20px;">
          <span style="font-size: 13px;">项目：
            <el-select v-model="projectValue" placeholder="请选择项目" @change="projectValueChange" filterable clearable>
              <el-option
                v-for="item in selectOptionsProject"
                :key="item.id"
                :label="item.project_name"
                :value="item.id">
              </el-option>
            </el-select>
          </span>
          <span style="margin-left: 20px; font-size: 13px;">模块：
            <el-select v-model="moduleValue" placeholder="请选择模块" @change="moduleValueChange" filterable clearable>
              <el-option
                v-for="item in selectOptionsModule"
                :key="item.id"
                :label="item.module_name"
                :value="item.id">
              </el-option>
            </el-select>
          </span>
        </div>
      </div>
       <!-- 当前接口信息展示 -->
       <el-table :data="tableDataCase" @selection-change="handleSelectionChange" style="width: 100%">
        <el-table-column
          type="selection"
          width="55px">
        </el-table-column>
        <el-table-column
          prop="case_id"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="case_name"
          label="用例名称">
        </el-table-column>
        <el-table-column
          prop="project_id"
          label="所属项目">
        </el-table-column>
        <el-table-column
          prop="module_id"
          label="所属模块">
        </el-table-column>
        <el-table-column
          prop="page_id"
          label="所属页面">
        </el-table-column>
        <el-table-column
          prop="description"
          label="动作描述">
        </el-table-column>
        <el-table-column
          prop="status"
          label="动作状态">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 接口分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <el-dialog
      :title=" isAdd ? '新增用例':'编辑用例' "
      :visible.sync="uiCaseDialogVisible"
      width="60%"
      :before-close="dialogCloseHandle">
      <el-tabs v-model="activeTagName">
        <el-tab-pane label="基本信息" name="caseBaseInfo">
          <el-form :model="uiBaseInfoRuleForm" :rules="uiBaseInfoRules" ref="uiBaseInfoRuleFormRef" label-width="120px" class="demo-ruleForm">
            <el-form-item v-if="!isAdd" label="用例ID" prop="case_id">
              <el-input v-model="uiBaseInfoRuleForm.case_id" disabled></el-input>
            </el-form-item>
            <el-form-item label="用例名称" prop="case_name">
              <el-input v-model="uiBaseInfoRuleForm.case_name"></el-input>
            </el-form-item>
            <el-form-item label="所属页面" prop="page_id">
              <!-- <el-input v-model="uiBaseInfoRuleForm.page_id" :disabled=" isAdd ? false : true "></el-input> -->
              <el-cascader
                v-model="pageCascaderValue"
                :options="pageCascaderOptions"
                :props="{ expandTrigger: 'hover', label: 'name', value: 'id'}"
                @change="pageHandleChange"
                style="width: 100%;">
              </el-cascader>
            </el-form-item>
            <el-form-item label="是否开启">
              <el-switch v-model="uiBaseInfoRuleForm.status" active-value="1" inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="用例描述" prop="description">
              <el-input type="textarea" v-model="uiBaseInfoRuleForm.description"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="用例步骤" name="caseStep">
          <div>
            <!-- <el-cascader
              v-model="caseStepIdList"
              :options="stepOptions"
              :props="{ expandTrigger: 'hover', multiple: true }"
              collapse-tags
              placeholder="选择增加的步骤">
            </el-cascader> -->
            <el-select
              v-model="caseStepvalue"
              multiple
              collapse-tags
              style="margin-left: 20px;"
              placeholder="请选择">
              <el-option
                v-for="item in caseStepoptions"
                :key="item.step_id"
                :label="item.step_name"
                :value="item.step_id">
              </el-option>
            </el-select>
            <el-button type="primary" @click="addCaseStepHandle" style="margin-left: 50px;" :disabled="caseStepvalue.length>0 ? false:true">新增</el-button>
          </div>
          <el-table
            :data="stepTableData"
            style="width: 100%"
            max-height="250"
            size="mini">
            <el-table-column
              width="50px"
              type="index"
              label="序号">
            </el-table-column>
            <el-table-column
              prop="step_id"
              label="步骤ID">
            </el-table-column>
            <el-table-column
              prop="step_name"
              label="步骤名称">
            </el-table-column>
            <el-table-column
              prop="description"
              label="备注信息">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-button @click.native.prevent="deleteRow(scope.$index, stepTableData)" type="text" size="small">移除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uiCaseDialogVisible = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEdit">编 辑</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="运行环境"
      :visible.sync="dialogVisibleRunEnV"
      width="30%">
      <el-select v-model="envValue" placeholder="请选择运行环境">
        <el-option
          v-for="item in envOptions"
          :key="item.env_id"
          :label="item.hub_url"
          :value="item.env_id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleRunEnV = false">取 消</el-button>
        <el-button type="primary" @click="handleRun">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryCaseList, addCase, editCase, deleteCase, runCase } from '@/services/uiCase.js'
import { queryEnvList } from '@/services/uiEnv.js'
import { queryStepList, queryStepById } from '@/services/uiStep.js'
import { queryCascader } from '@/services/uiCascader.js'
import { queryProjectAll } from '@/services/project.js'
import { queryModuleAll } from '@/services/module.js'
export default {
  name: 'UiCase',
  data () {
    return {
      isAdd: true,
      tableDataCase: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      multipleSelection: [],
      multipleSelections: [],
      projectValue: '',
      selectOptionsProject: [],
      moduleValue: '',
      selectOptionsModule: [],
      pageCascaderValue: [],
      pageCascaderOptions: [],
      uiCaseDialogVisible: false,
      activeTagName: 'caseBaseInfo',
      uiBaseInfoRuleForm: {
        case_id: '',
        case_name: '',
        page_id: '',
        description: '',
        status: '',
        created_time: ''
      },
      uiBaseInfoRules: {
        case_name: [
          { required: true, message: '请输入用例名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        page_id: [
          { required: true, message: '请选择所属页面', trigger: 'blur' }
        ],
        description: [
          { required: false, message: '编写描述信息', trigger: 'blur' }
        ]
      },
      stepTableData: [],
      caseStepvalue: [],
      caseStepoptions: [],
      dialogVisibleRunEnV: false,
      envValue: '',
      envOptions: []
    }
  },
  created () {
    this.loadGetCaseList()
    this.loadGetProjectList()
  },
  methods: {
    async loadGetCaseList () {
      const queryObj = {
        project_id: this.projectValue,
        module_id: this.moduleValue,
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryCaseList(queryObj)
      console.log('返回数据', res)
      console.log('返回数据', res.data)
      if (res.status === 200) {
        this.tableDataCase = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    async loadGetStepList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryStepList(queryObj)
      if (res.status === 200) {
        this.caseStepoptions = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    async loadGetCascader () {
      const queryObj = { cascader_type: 'page' }
      const { data: res } = await queryCascader(queryObj)
      if (res.status === 200) {
        this.pageCascaderOptions = res.data
      }
    },
    async loadGetProjectList () {
      const queryObj = {
        query_type: 'query_all'
      }
      const { data: res } = await queryProjectAll(queryObj)
      if (res.status === 1) {
        this.selectOptionsProject = res.data
      }
    },
    async loadGetModuleList () {
      const queryObj = {
        project_id: this.projectValue,
        query_type: 'query_all'
      }
      const { data: res } = await queryModuleAll(queryObj)
      if (res.status === 1) {
        this.selectOptionsModule = res.data
      }
    },
    // 接口信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadGetCaseList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadGetCaseList()
      console.log(`当前页: ${val}`)
    },
    // 操作当前选中的用例
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(this.multipleSelection, '<--选中的数据')
      // 清空之前的 multipleSelections 值，取消选择后重新更新数据
      this.multipleSelections = []
      // 此时 取消/选择 用例的时候，让对应的 ID 信息更新在指定的数据中
      this.multipleSelection.map((item) => {
        // 每次存储数据的时候需要判断当前的 ID 是否已经存在
        if (!this.multipleSelections.includes(item.case_id)) {
          this.multipleSelections.push(item.case_id)
        }
      })
      console.log(this.multipleSelections, '<--要传入的测试用例ID列表')
    },
    async handleRunDialog () {
      if (!this.multipleSelections.length > 0) {
        return this.$message.warning('请选择要执行的用例')
      }
      this.dialogVisibleRunEnV = true

      // 获取对应项目下的环境信息
      const queryEnvObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: resEnv } = await queryEnvList(queryEnvObj)
      if (resEnv.status !== 200) {
        this.$message.warning('获取环境信息失败')
      }
      this.envOptions = resEnv.data
    },
    async projectValueChange () {
      this.moduleValue = ''
      this.selectOptionsModule = []
      this.loadGetCaseList()
      this.loadGetModuleList()
    },
    async moduleValueChange () {
      this.loadGetCaseList()
    },
    dialogCloseHandle (done) {
      // doalog关闭提示
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    async addCaseStepHandle () {
      for (const caseStepId of this.caseStepvalue) {
        console.log(caseStepId)
        const queryObj = {
          query_type: 'query_detail',
          step_id: caseStepId
        }
        const { data: res } = await queryStepById(queryObj)
        if (res.status === 200) {
          this.stepTableData.push(res.data)
        }
      }
      this.caseStepvalue = []
    },
    deleteRow (index, rows) {
      rows.splice(index, 1);
    },
    handleAdd () {
      console.log('新增用例')
      this.isAdd = true
      this.uiBaseInfoRuleForm = {
        case_id: '',
        case_name: '',
        page_id: '',
        description: '',
        status: ''
      }
      this.pageCascaderValue = []
      this.stepTableData = []
      this.uiCaseDialogVisible = true
      this.loadGetCascader()
      this.loadGetStepList()
    },
    async handleEdit (rowData) {
      console.log('编辑用例')
      console.log(rowData)
      console.log(rowData.step_id.split(','))
      this.isAdd = false
      this.uiBaseInfoRuleForm = {
        case_id: rowData.case_id,
        case_name: rowData.case_name,
        page_id: rowData.page_id,
        description: rowData.description,
        status: rowData.status
      }
      this.loadGetCascader()
      this.pageCascaderValue = [rowData.project_id, rowData.module_id, rowData.page_id]
      this.uiCaseDialogVisible = true
      this.stepTableData = []
      this.loadGetStepList()
      for (const stepId of rowData.step_id.split(',')) {
        const queryObj = {
          query_type: 'query_detail',
          step_id: stepId
        }
        console.log('查询数据', queryObj)
        const { data: res } = await queryStepById(queryObj)
        if (res.status !== 200) {
          this.$message.warning('通过ID获取步骤信息失败')
        }
        this.stepTableData.push(res.data)
      }
    },
    handleDel (rowData) {
      console.log('删除用例')
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { case_id: rowData.case_id }
        const { data: res } = await deleteCase(deleteObj)
        if (res.status === 200) {
          this.$message.success('删除用例成功')
          this.loadGetCaseList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async processAdd () {
      console.log('添加用例')
      const stepIdList = []
      for (const stepInfo of this.stepTableData) {
        stepIdList.push(stepInfo.step_id.toString())
      }
      const addObj = {
        operation: 'add',
        case_name: this.uiBaseInfoRuleForm.case_name,
        page_id: this.uiBaseInfoRuleForm.page_id,
        step_id: stepIdList,
        description: this.uiBaseInfoRuleForm.description,
        status: this.uiBaseInfoRuleForm.status
      }
      const { data: res } = await addCase(addObj)
      if (res.status !== 200) {
        this.$message.warning(res.message)
      }
      this.uiCaseDialogVisible = false
      this.loadGetCaseList()
      this.$message.success('添加用例成功')
      this.$refs.uiBaseInfoRuleFormRef.resetFields()
      this.stepTableData = []
    },
    async processEdit () {
      const stepIdList = []
      for (const stepInfo of this.stepTableData) {
        stepIdList.push(stepInfo.step_id.toString())
      }
      const editObj = this.uiBaseInfoRuleForm
      editObj.step_id = stepIdList
      const { data: res } = await editCase(editObj)
      if (res !== 200) {
        this.$message.warning(res.message)
      }
      this.uiCaseDialogVisible = false
      this.$message.success('编辑成功')
      this.loadGetCaseList()
      this.$refs.uiBaseInfoRuleFormRef.resetFields()
      this.stepTableData = []
    },
    async handleRun () {
      const deBugObj = {
        env_id: this.envValue,
        case_id_list: this.multipleSelections,
        executionMode: '手动执行用例'
      }
      console.log('运行用例数据', deBugObj)
      // 检测是否勾选用例，判定执行类型
      if (this.multipleSelections.length === 1) {
        const { data: res } = await runCase(deBugObj)
        console.log(res, '<-- 运行测试用例结果')
        if (res.status === 200) {
          console.log(res, '<-- 用例数为1时')
          // this.$store.commit('saveReportInfo', res.data)
          // this.$router.push('/apiReport/info')
          this.$message.success('执行用例成功')
        } else this.$message.error(res.message)
      } else if (this.multipleSelections.length > 1) {
        const { data: res } = await runCase(deBugObj)
        console.log(res, '<-- 运行测试用例结果')
        if (res.status === 200) {
          this.$message.success('运行测试用例成功，请在测试报告中查看运行结果')
        } else this.$message.error(res.message)
      }
      this.dialogVisibleRunEnV = false
      this.envValue = ''
    },
    pageHandleChange () {
      // this.ruleFormPage.project_id = this.pageCascaderValue[0]
      // this.ruleFormPage.module_id = this.pageCascaderValue[1]
      this.uiBaseInfoRuleForm.page_id = this.pageCascaderValue[this.pageCascaderValue.length - 1]
    }
  }
}
</script>

<style>

</style>
