import request from '@/utils/request.js'
import { urlPath } from './uiPage'

// 查询用例列表信息
// export const queryCaseList = (data) => {
//   return request({
//     method: 'GET',
//     url: 'uiCase',
//     params: {
//       ...data
//     }
//   })
// }
export function queryCaseList (data) {
  return request({
    method: 'GET',
    url: urlPath + 'uiCase',
    params: {
      ...data
    }
  })
}

// 依据ID查询用例信息
export const queryCaseById = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiCase',
    params: {
      ...data
    }
  })
}

// 添加测试用例
export const addCase = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'uiCase',
    data
  })
}

// 编辑测试用例
export const editCase = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'uiCase',
    data
  })
}

// 删除测试用例
export const deleteCase = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'uiCase',
    data
  })
}

// 运行测试用例
export const runCase = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'uiRunCase',
    data
  })
}
