import request from '@/utils/request.js'
import { urlPath } from './uiPage'

// 查询步骤列表信息
export const queryStepList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiStep',
    params: {
      ...data
    }
  })
}

// 依据ID查询步骤信息
export const queryStepById = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'uiStep',
    params: {
      ...data
    }
  })
}

// 添加步骤信息
export const addStep = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'uiStep',
    data
  })
}

// 编辑步骤信息
export const editStep = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'uiStep',
    data
  })
}

// 删除步骤信息
export const deleteStep = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'uiStep',
    data
  })
}
